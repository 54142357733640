import {
  SAVE_CLIENT_REQUEST,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
};

export default function(state = initialState, action) {
  const { type, error } = action;
  switch (type) {
    case SAVE_CLIENT_REQUEST:
      return { ...state, isFetching: true, error: null, response: null };
    case SAVE_CLIENT_SUCCESS:
      return { ...state, isFetching: false };
    case SAVE_CLIENT_FAILURE:
      return { ...state, error: error.message, isFetching: false };
    default:
      return state;
  }
}
