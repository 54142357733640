import Compress from 'compress.js';
import store from '../../../redux/store';
import {
  SAVE_CLIENT_REQUEST,
  SAVE_CLIENT_SUCCESS,
  SAVE_CLIENT_FAILURE,
  CHANGE_CLIENT_DATA,
} from './actionTypes';
import setClient from './setClient';
import baseUrl from '../../../resources/api';

export default function() {
  return dispatch => {
    dispatch({ type: SAVE_CLIENT_REQUEST });
    const state = store.getState();
    const { selectedClient } = state;
    const clientData = { ...state.clientData };
    const img = clientData.alaprajz;
    const formData = new FormData();
    if (selectedClient) formData.append('selectedClient', selectedClient);
    if (img) {
      if (img.src.slice?.(0, 5) === 'data:') {
        const [base64prefix, base64str] = img.src.split(',');
        const imgExt = base64prefix.slice(5, base64prefix.indexOf(';'));
        const blob = Compress.convertBase64ToFile(base64str, imgExt);
        const filename = `${Date.now().toString()}.jpg`;
        formData.append('image', blob, filename);
        clientData.alaprajz = { src: filename };
      } else {
        const urlSplit = clientData.alaprajz.src.split('/');
        clientData.alaprajz.src = urlSplit[urlSplit.length - 1];
      }
    }
    const clientDataBlob = new Blob([JSON.stringify(clientData)], {
      type: 'application/json',
    });
    formData.append('clientData', clientDataBlob);
    return fetch(`${baseUrl}/upload/client`, {
      method: 'post',
      mode: 'cors',
      body: formData,
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch({ type: SAVE_CLIENT_SUCCESS });
        if (response?.clientSaveDBResult?.insertId)
          dispatch(setClient(response.clientSaveDBResult.insertId));
        if (response?.clientData?.alaprajz?.src)
          dispatch({
            type: CHANGE_CLIENT_DATA,
            payload: {
              alaprajz: {
                src: `${baseUrl}/alaprajz_imgs/${response.clientData.alaprajz.src}`,
              },
            },
          });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: SAVE_CLIENT_FAILURE, error });
      });
  };
}
