import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
} from './actionTypes';
import baseUrl from '../../../resources/api';

export default function() {
  return dispatch => {
    dispatch({ type: GET_CLIENTS_REQUEST });
    return fetch(`${baseUrl}/clients`, {
      method: 'get',
      mode: 'cors',
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch({ type: GET_CLIENTS_SUCCESS, response });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: GET_CLIENTS_FAILURE, error });
      });
  };
}
