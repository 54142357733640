export default function hutesTable(template) {
  let xml = `<w:tbl>
  <w:tblPr>
    <w:tblW w:w="5000" w:type="pct" />
    <w:jc w:val="center"/>
    <w:tblBorders><w:top w:val="single" w:sz="18" w:space="0" w:color="auto"/><w:left w:val="single" w:sz="18" w:space="0" w:color="auto"/><w:bottom w:val="single" w:sz="18" w:space="0" w:color="auto"/><w:right w:val="single" w:sz="18" w:space="0" w:color="auto"/><w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto"/></w:tblBorders>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol w:type="dxa" w:w="3000"/>
    <w:gridCol/>
    <w:gridCol/>
  </w:tblGrid>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:vMerge w:val="restart"/>
        <w:vAlign w:val="center"/>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Élelmiszer fajta</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Előírt tárolási hőmérséklet</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:vMerge/>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p><w:r><w:t></w:t></w:r></w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Szállítás-átvétel</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Raktározás</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (
    template.serteshus ||
    template.marhahus ||
    template.vadon_elo_allatok ||
    template.elokeszitett_hus
  )
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Friss vagy előkészített hús</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +7 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.baromfi_felek)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Friss baromfi hús</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +4 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +4 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.tokeh_belsoseg)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Belsőség</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +3 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.tokeh_daralt_hus)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Darálthús</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +2 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.alapa_huskeszitmenyek)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Húskészítmény</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +5 °C, illetve a gyártó által meghatározott hőmérséklet</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.alapa_tejtermekek)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Tejtermékek</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +5 °C, illetve a gyártó által meghatározott hőmérséklet</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.alapa_tojas)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:tcPr>
        <w:tcW w:w="3000" w:type="dxa"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>Hűtött tojástermék</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:tcPr>
        <w:gridSpan w:val="2"/>
      </w:tcPr>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>0- +4 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  xml += `</w:tbl>`;
  return xml;
}
