import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FilterListIcon from '@material-ui/icons/FilterList';
import Paper from '@material-ui/core/Paper';
import getClientsDetails from '../../redux/actions/getClientsDetails';
import { processTableData, transFilt } from './clientsTableLogic';
import ActionButtons from '../misc/ActionButtons';
import NewClientButton from '../misc/NewClientButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '95%',
    [theme.breakpoints.up('lg')]: {
      width: '90%',
    },
    marginTop: 40,
  },
  table: {
    minWidth: 650,
  },
  header: {
    backgroundColor: theme.palette.grey[300],
  },
  toolbar: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(1),
  },
  titleIcon: {
    flex: '1 1 100%',
    display: 'flex',
    alignItems: 'center',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const fields = [
  { name: 'nr', title: '#', numeric: true },
  { name: 'cegnev', title: 'Cég neve', numeric: false },
  { name: 'telepnev', title: 'Telephely neve', numeric: false },
  { name: 'ugyfelkat', title: 'Ügyfél-kategória', numeric: false },
  { name: 'kapcsnev', title: 'Kapcsolattartó neve', numeric: false },
  { name: 'kapcstel', title: 'Kapcsolattartó tel.', numeric: true },
  { name: 'kiadasdatum', title: 'Kiadás dátuma', numeric: true, minWidth: 140 },
  { name: 'kovvizsg', title: 'Köv. felülvizsgálat', numeric: true },
];

export default function SimpleTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clientsDetails.response);
  const isFetching = useSelector(state => state.clientsDetails.isFetching);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(fields[0].name);
  const [filterValue, setFilter] = React.useState('');
  useEffect(() => {
    dispatch(getClientsDetails());
  }, [dispatch]);

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = e => {
    setFilter(e.target.value);
    setPage(0);
  };

  if (isFetching || !clients) return <div />;

  const dataToDisplay = processTableData(
    filterValue,
    order,
    orderBy,
    rowsPerPage,
    page,
  )(clients);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.titleIcon}>
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Ügyfelek
          </Typography>
          <NewClientButton />
        </div>
        <TextField
          id="filter-input"
          value={filterValue}
          onChange={handleFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FilterListIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <Table className={classes.table} aria-label="table">
        <TableHead>
          <TableRow className={classes.header}>
            {fields.map(field => (
              <TableCell
                key={field.name}
                align={field.numeric ? 'right' : 'left'}
                style={{ minWidth: field.minWidth }}
              >
                <TableSortLabel
                  active={orderBy === field.name}
                  direction={orderBy === field.name ? order : 'asc'}
                  onClick={createSortHandler(field.name)}
                >
                  {field.title}
                  {orderBy === field.name ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="left" style={{ minWidth: 154 }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToDisplay.map(row => (
            <TableRow key={row.id}>
              {fields.map(field => (
                <TableCell
                  key={field.name}
                  title={row[field.name].title}
                  align={field.numeric ? 'right' : 'left'}
                >
                  {row[field.name].name || row[field.name]}
                </TableCell>
              ))}
              <TableCell align="left">
                <ActionButtons id={row.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: 'Mind', value: -1 }]}
        component="div"
        count={transFilt(filterValue)(clients).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Sor / oldal"
        backIconButtonText="Előző oldal"
        nextIconButtonText="Következő oldal"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} / ${count}`
        }
      />
    </TableContainer>
  );
}
