import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FixedBtnPanel from './FixedBtnPanel';
import Form from './Form';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

function App() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.container}>
      <Form />
      <FixedBtnPanel />
    </div>
  );
}

export default App;
