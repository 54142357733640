import React, { useState, useEffect } from 'react';

// export default class FadeIn extends React.Component {
//   state = {
//     maxIsVisible: 0,
//   };

//   componentDidMount() {
//     const count = React.Children.count(this.props.children);
//     let i = 0;
//     this.interval = setInterval(() => {
//       i++;
//       if (i > count) clearInterval(this.interval);

//       this.setState({ maxIsVisible: i });
//     }, this.delay);
//   }

//   componentWillUnmount() {
//     clearInterval(this.interval);
//   }

//   render() {
//     const { children, delay = 50, transitionDuration = 250 } = this.props;
//     return (
//       <div>
//         {React.Children.map(children, (child, i) => {
//           return (
//             <div
//               style={{
//                 transition: `opacity ${transitionDuration}ms, top ${transitionDuration}ms`,
//                 position: 'relative',
//                 top: this.state.maxIsVisible > i ? 0 : 20,
//                 opacity: this.state.maxIsVisible > i ? 1 : 0,
//               }}
//             >
//               {child}
//             </div>
//           );
//         })}
//       </div>
//     );
//   }
// }

export default function FadeIn({
  children,
  delay = 100,
  transitionDuration = 250,
}) {
  const count = React.Children.count(children);
  const [maxIsVisible, setMaxIsVisible] = useState(0);
  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      i += 1;
      if (i > count) clearInterval(interval);
      setMaxIsVisible(i);
    }, delay);
    return () => clearInterval(interval);
  }, [count, delay]);

  return (
    <div>
      {React.Children.map(children, (child, i) => {
        return (
          <div
            style={{
              transition: `opacity ${transitionDuration}ms, top ${transitionDuration}ms`,
              position: 'relative',
              top: maxIsVisible > i ? 0 : 20,
              opacity: maxIsVisible > i ? 1 : 0,
            }}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
}
