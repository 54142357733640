import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';
import rootReducer from './reducers';

export default createStore(
  enableBatching(rootReducer),
  composeWithDevTools(applyMiddleware(thunk)),
);
