import store from '../../../redux/store';
import {
  SAVE_HACCP_REQUEST,
  SAVE_HACCP_SUCCESS,
  SAVE_HACCP_FAILURE,
} from './actionTypes';
import setHaccp from './setHaccp';
import baseUrl from '../../../resources/api';

export default function() {
  return dispatch => {
    const state = store.getState();
    const { haccpData, selectedClient, selectedHaccp } = state;
    if (selectedClient === 'new_client') return null;
    dispatch({ type: SAVE_HACCP_REQUEST });
    const haccpDataBlob = new Blob([JSON.stringify(haccpData)], {
      type: 'application/json',
    });
    const formData = new FormData();
    if (selectedClient && selectedHaccp !== 'new_client')
      formData.append('selectedClient', selectedClient);
    formData.append('haccpData', haccpDataBlob);
    if (selectedHaccp && selectedHaccp !== 'new_haccp')
      formData.append('selectedHaccp', selectedHaccp);
    return fetch(`${baseUrl}/upload/haccp`, {
      method: 'post',
      mode: 'cors',
      body: formData,
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch({ type: SAVE_HACCP_SUCCESS });
        if (response?.haccpSaveDBResult?.insertId)
          dispatch(
            setHaccp(response.haccpSaveDBResult.insertId, selectedClient),
          );
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: SAVE_HACCP_FAILURE, error });
      });
  };
}
