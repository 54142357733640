export default function zsirTable(template) {
  let xml = `<w:tbl>
  <w:tblPr>
    <w:tblW w:w="4000" w:type="dxa" />
    <w:jc w:val="center"/>
    <w:tblBorders><w:top w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:left w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:right w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto"/></w:tblBorders>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol/>
    <w:gridCol/>
  </w:tblGrid>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>zsiradék típusa</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>sütési idő</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.napraforgoolaj)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>napraforgóolaj</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>kb. 8–10 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.palmaolaj)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>pálmaolaj</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>kb. 10–13 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.serteszsir)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>sertészsír</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:rFonts w:ascii="Times New Roman" w:hAnsi="Times New Roman" w:cs="Times New Roman"/>
            <w:sz w:val="24"/>
          </w:rPr>
          <w:t>kb. 18–20 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  xml += `</w:tbl>`;
  return xml;
}
