import { SET_SELECTED_CLIENT } from '../actions/actionTypes';

export default function(state = 'new_client', action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_CLIENT:
      return payload;
    default:
      return state;
  }
}
