export default function generatePdf(
  questions,
  active,
  cselTervs,
  comments,
  imgsrcs,
  logo,
  data,
) {
  const toIns = [];
  const appendixImgs = [];
  const allImgSrcs = [];
  let imgCount = 1;
  let imgCountApp = 1;
  for (let i = 1; i <= Object.keys(questions).length; i++) {
    for (let k = 0; k < questions[i].length; k++) {
      if (active[i] && (active[i][k] === 1 || active[i][k] === 2)) {
        toIns.push([]);
        if (questions[i][k].isMain) {
          toIns[toIns.length - 1].push({
            text: questions[i][k].name,
            bold: true,
            fontSize: 17,
            margin: [0, 5, 0, 5],
            alignment: 'center',
            colSpan: 3,
          });
          toIns[toIns.length - 1].push({});
          toIns[toIns.length - 1].push({});
        } else {
          toIns[toIns.length - 1].push({
            text: questions[i][k].name,
            fontSize: 12,
          });
          toIns[toIns.length - 1].push(
            active[i][k] === 1
              ? { text: 'Megfelelő', alignment: 'center', fillColor: '#66bb6a' }
              : {
                  text: 'Nem megfelelő',
                  alignment: 'center',
                  fillColor: '#df4755',
                },
          );
          if (comments[i] && comments[i][k]) {
            toIns[toIns.length - 1].push(comments[i][k]);
          } else {
            toIns[toIns.length - 1].push('');
          }
          if (imgsrcs[i] && imgsrcs[i][k] && imgsrcs[i][k].length) {
            allImgSrcs.push(...imgsrcs[i][k]);
            const columnIns = [];
            for (let j = 0; j < imgsrcs[i][k].length; j += 6) {
              const images = [];
              const keys = [];
              const imgLabels = [];
              if (imgsrcs[i][k][j]) {
                images[0] = imgsrcs[i][k][j];
                keys[0] = 'image';
                imgLabels[0] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[0] = '';
                keys[0] = 'text';
                imgLabels[0] = '';
              }
              if (imgsrcs[i][k][j + 1]) {
                images[1] = imgsrcs[i][k][j + 1];
                keys[1] = 'image';
                imgLabels[1] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[1] = '';
                keys[1] = 'text';
                imgLabels[1] = '';
              }
              if (imgsrcs[i][k][j + 2]) {
                images[2] = imgsrcs[i][k][j + 2];
                keys[2] = 'image';
                imgLabels[2] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[2] = '';
                keys[2] = 'text';
                imgLabels[2] = '';
              }
              if (imgsrcs[i][k][j + 3]) {
                images[3] = imgsrcs[i][k][j + 3];
                keys[3] = 'image';
                imgLabels[3] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[3] = '';
                keys[3] = 'text';
                imgLabels[3] = '';
              }
              if (imgsrcs[i][k][j + 4]) {
                images[4] = imgsrcs[i][k][j + 4];
                keys[4] = 'image';
                imgLabels[4] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[4] = '';
                keys[4] = 'text';
                imgLabels[4] = '';
              }
              if (imgsrcs[i][k][j + 5]) {
                images[5] = imgsrcs[i][k][j + 5];
                keys[5] = 'image';
                imgLabels[5] = `${imgCount}. kép`;
                imgCount++;
              } else {
                images[5] = '';
                keys[5] = 'text';
                imgLabels[5] = '';
              }
              columnIns.push(
                {
                  columns: [
                    {
                      fit: [80, 80],
                      [keys[0]]: images[0],
                      alignment: 'center',
                    },
                    {
                      fit: [80, 80],
                      [keys[1]]: images[1],
                      alignment: 'center',
                    },
                    {
                      fit: [80, 80],
                      [keys[2]]: images[2],
                      alignment: 'center',
                    },
                    {
                      fit: [80, 80],
                      [keys[3]]: images[3],
                      alignment: 'center',
                    },
                    {
                      fit: [80, 80],
                      [keys[4]]: images[4],
                      alignment: 'center',
                    },
                    {
                      fit: [80, 80],
                      [keys[5]]: images[5],
                      alignment: 'center',
                    },
                  ],
                  colSpan: 3,
                },
                {
                  columns: [
                    {
                      text: imgLabels[0],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                    {
                      text: imgLabels[1],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                    {
                      text: imgLabels[2],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                    {
                      text: imgLabels[3],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                    {
                      text: imgLabels[4],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                    {
                      text: imgLabels[5],
                      alignment: 'center',
                      fit: [80, 80],
                      fontSize: 9,
                      margin: [0, 2, 0, 10],
                    },
                  ],
                  colSpan: 3,
                },
              );
            }
            toIns.push([
              {
                stack: [...columnIns],
                colSpan: 3,
              },
            ]);
          }
        }
      }
    }
    if (cselTervs[i] && cselTervs[i].filter(x => x.isSelected).length) {
      if (
        !active[i] ||
        (active[i] &&
          !Object.values(active[i]).filter(x => x === 1 || x === 2).length)
      ) {
        toIns.push([]);
        questions[i].forEach(x => {
          if (x.isMain) {
            toIns[toIns.length - 1].push({
              text: x.name,
              bold: true,
              fontSize: 17,
              margin: [0, 5, 0, 5],
              alignment: 'center',
              colSpan: 3,
            });
            toIns[toIns.length - 1].push({});
            toIns[toIns.length - 1].push({});
          }
        });
      }
      const cselTervTextArr = [];
      cselTervs[i]
        .filter(x => x.isSelected && x.name)
        .forEach(x => {
          if (!x.isMain) {
            cselTervTextArr.push({ text: `${x.name}\n` });
          } else {
            cselTervTextArr.push({ text: `${x.name}\n`, bold: true });
          }
        });
      toIns.push([
        {
          stack: [
            {
              stack: [{ text: 'Cselekvési terv:', bold: true, fontSize: 13 }],
              margin: [0, 0, 0, 5],
            },
            { text: cselTervTextArr },
          ],
          colSpan: 3,
        },
      ]);
    }
  }

  let appendixHeader = {
    text: 'Függelék',
    bold: true,
    alignment: 'center',
    fontSize: 26,
    pageBreak: 'before',
  };
  if (allImgSrcs.length === 0) {
    appendixHeader = '';
  }

  for (let j = 0; j < allImgSrcs.length; j += 2) {
    const images = [];
    const keys = [];
    const imgLabels = [];
    if (allImgSrcs[j]) {
      images[0] = allImgSrcs[j];
      keys[0] = 'image';
      imgLabels[0] = `${imgCountApp}. kép`;
      imgCountApp++;
    } else {
      images[0] = '';
      keys[0] = 'text';
      imgLabels[0] = '';
    }
    if (allImgSrcs[j + 1]) {
      images[1] = allImgSrcs[j + 1];
      keys[1] = 'image';
      imgLabels[1] = `${imgCountApp}. kép`;
      imgCountApp++;
    } else {
      images[1] = '';
      keys[1] = 'text';
      imgLabels[1] = '';
    }
    appendixImgs.push(
      {
        columns: [
          {
            fit: [230, 230],
            [keys[0]]: images[0],
            alignment: 'center',
            margin: [0, 25, 0, 0],
          },
          {
            fit: [230, 230],
            [keys[1]]: images[1],
            alignment: 'center',
            margin: [0, 25, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: imgLabels[0],
            alignment: 'center',
            fit: [230, 230],
            fontSize: 11,
            margin: [0, 4, 0, 10],
          },
          {
            text: imgLabels[1],
            alignment: 'center',
            fit: [230, 230],
            fontSize: 11,
            margin: [0, 4, 0, 10],
          },
        ],
      },
    );
  }

  const correct = Object.values(active).reduce((acc, curr) => {
    const currObj = { ...curr };
    currObj[0] = 0;
    const count = Object.values(currObj).filter(x => x === 1).length;
    return acc + count;
  }, 0);
  const all = Object.values(active).reduce((acc, curr) => {
    const currObj = { ...curr };
    currObj[0] = 0;
    const count = Object.values(currObj).filter(x => x === 1 || x === 2).length;
    return acc + count;
  }, 0);
  const auditResultRatio = `${correct}/${all}`;
  const auditResultPercent = `${((100 * correct) / all).toFixed(2)}%`;

  let ugyfelKat = '-';
  if (data.ugyfelKat === 'gyemant') ugyfelKat = 'Gyémánt';
  if (data.ugyfelKat === 'platina') ugyfelKat = 'Platina';
  if (data.ugyfelKat === 'arany') ugyfelKat = 'Arany';
  if (data.ugyfelKat === 'ezust') ugyfelKat = 'Ezüst';

  const docDefinition = {
    pageSize: 'A4',
    printMediaType: true,
    footer(currentPage) {
      return {
        text: currentPage !== 1 ? `- ${currentPage.toString()} -` : '',
        alignment: 'center',
      };
    },
    content: [
      {
        image: logo,
        alignment: 'center',
        fit: [160, 160],
        margin: [0, 50, 0, 0],
      },
      {
        text: 'Időszakos felülvizsgálat',
        bold: true,
        alignment: 'center',
        fontSize: 30,
        margin: [0, 120, 0, 0],
      },
      {
        text: data.telephely,
        bold: true,
        alignment: 'center',
        fontSize: 24,
        margin: [0, 5, 0, 0],
      },
      {
        text: `Audit eredménye: ${auditResultPercent}`,
        bold: true,
        alignment: 'center',
        fontSize: 16,
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            stack: [
              {
                text: 'Cégnév:',
                bold: true,
                fontSize: 14,
              },
              {
                text: data.cegnev || '-',
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: 'Telephely:',
                bold: true,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: data.telephely || '-',
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: 'Cím:',
                bold: true,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: data.cim || '-',
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: 'Ügyfél-kategória:',
                bold: true,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: ugyfelKat,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
            ],
            margin: [25, 160, 0, 0],
          },
          {
            stack: [
              {
                text: 'Dátum:',
                bold: true,
                fontSize: 14,
              },
              {
                text: `${data.date.replace(/-/g, '. ')}.`,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: 'Készítette:',
                bold: true,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: data.keszitette || '-',
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: 'Eredmény:',
                bold: true,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
              {
                text: `${auditResultRatio} - ${auditResultPercent}`,
                fontSize: 14,
                margin: [0, 5, 0, 0],
              },
            ],
            width: 'auto',
            margin: [5, 203, 35, 0],
          },
        ],
        pageBreak: 'after',
      },
      {
        table: {
          //          headerRows: 1,
          widths: ['*', 80, '*'],
          dontBreakRows: true,
          body: [
            // [
            //   {
            //     text: 'Kérdés',
            //     alignment: 'center',
            //     fillColor: '#cccccc',
            //   },
            //   {
            //     text: 'Eredmény',
            //     alignment: 'center',
            //     fillColor: '#cccccc',
            //   },
            //   {
            //     text: 'Megjegyzés',
            //     alignment: 'center',
            //     fillColor: '#cccccc',
            //   },
            // ],
            ...toIns,
          ],
        },
        layout: {
          paddingLeft: () => 8,
          paddingRight: () => 8,
          paddingTop: () => 8,
          paddingBottom: () => 8,
        },
      },
      appendixHeader,
      ...appendixImgs,
    ],
  };
  return docDefinition;
}
