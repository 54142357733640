import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrivateRoute from './auth/PrivateRoute';
import Login from './auth/Login';
import Layout from './Layout';
import Clients from './pages/Clients';
import Wizard from '../wizard/components/App';
import Audit from '../audit/App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#320670',
    },
    secondary: {
      main: '#edb417',
    },
  },
});

function App() {
  const isVerifying = useSelector(state => state.auth.isVerifying);

  if (isVerifying) return <div />;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute exact path={['/', '/clients']}>
              <Clients />
            </PrivateRoute>
            <PrivateRoute exact path={['/wizard']}>
              <Wizard />
            </PrivateRoute>
            <PrivateRoute exact path={['/audit']}>
              <Audit />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
