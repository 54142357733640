import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from './Dialog';
import saveClient from '../../wizard/redux/actions/saveClient';
import setClient from '../../wizard/redux/actions/setClient';
import ClientDataForm from '../../wizard/components/ClientDataForm';
import getClientsDetails from '../../redux/actions/getClientsDetails';

export default function NewClientButton() {
  const dispatch = useDispatch();

  return (
    <Dialog
      buttonElem={
        <IconButton size="small" aria-label="new-client" title="Új ügyfél">
          <AddIcon />
        </IconButton>
      }
      content={<ClientDataForm />}
      action="Ment"
      actionFn={() => dispatch(saveClient())}
      openFn={() => dispatch(setClient('new_client'))}
      closeFn={() => dispatch(getClientsDetails())}
    />
  );
}
