import store from '../../redux/store';
import clientForm from '../resources/clientForm.json';
import haccpForm from '../resources/haccpForm.json';
import hutesTable from './tables/hutesTable';
import tarolasTable from './tables/tarolasTable';
import zsirTable from './tables/zsirTable';
import sutemenyTable from './tables/sutemenyTable';

function formatDate(date) {
  return `${date.replace(/-/g, '. ')}.`;
}

function generateListFor(prop, template) {
  const id = Object.keys(clientForm).find(key => clientForm[key].name === prop);
  const { options } = clientForm[id];
  const titles = [];
  function getActiveTitles(obj) {
    Object.keys(obj).forEach(key => {
      if (template[obj[key].name]) titles.push(obj[key].title);
      if (obj[key].options) getActiveTitles(obj[key].options);
    });
  }
  getActiveTitles(options);
  if (template[`${prop}_egyeb`]) titles.push(template[`${prop}_egyeb`]);
  return titles.join(', ');
}

function generateListForAdatok(prop, obj, template) {
  const id = Object.keys(obj).find(key => obj[key].name === prop);
  const { title, options, subsections } = obj[id];
  const titles = [];
  function getActiveTitles(optObj, arr) {
    Object.keys(optObj).forEach(key => {
      if (template[optObj[key].name]) {
        if (optObj[key].type === 'TextInput') {
          arr.push(template[optObj[key].name]);
        } else if (
          optObj[key].options ||
          template[`${optObj[key].name}_egyeb`]
        ) {
          const optArr = [];
          if (optObj[key].options) getActiveTitles(optObj[key].options, optArr);
          if (template[`${optObj[key].name}_egyeb`]) {
            optArr.push(template[`${optObj[key].name}_egyeb`]);
          }
          if (optArr.length)
            arr.push(`${optObj[key].title}: ${optArr.join(', ')}`);
          else arr.push(optObj[key].title);
        } else {
          arr.push(optObj[key].title);
        }
      }
    });
  }
  if (options) getActiveTitles(options, titles);
  let subsectionsArr = null;
  if (subsections) subsectionsArr = generateAdatok(subsections, template);
  if (template[`${obj[id].name}_egyeb`])
    titles.push(template[`${obj[id].name}_egyeb`]);
  if (template[`${obj[id].name}_main_egyeb`])
    subsectionsArr.push({ title: template[[`${obj[id].name}_main_egyeb`]] });
  const list = titles.join(' | ');
  return list || (subsectionsArr && subsectionsArr.length)
    ? {
        title,
        list,
        subsections: subsectionsArr,
      }
    : null;
}

function generateAdatok(form, template) {
  const adatok = [];
  Object.keys(form).forEach(key => {
    const item = generateListForAdatok(form[key].name, form, template);
    if (item) adatok.push(item);
  });
  return adatok;
}

function generateTakarHely(prop, template) {
  const id = Object.keys(haccpForm).find(key => haccpForm[key].name === prop);
  const { subsections } = haccpForm[id];
  const titles = [];
  function getActiveTitles(obj) {
    Object.keys(obj).forEach(key => {
      if (template[obj[key].name] && obj[key].name !== 'gazdasagi_bejarat')
        titles.push(obj[key].title);
    });
  }
  getActiveTitles(subsections);
  return titles.join(', ');
}

function generateBeszer(template) {
  const names = [
    'alapa_tokehus',
    'hal_es_egyeb_halaszati_termekek',
    'alapa_huskeszitmenyek',
  ];
  const id = Object.keys(haccpForm).find(
    key => haccpForm[key].name === 'alapanyagok',
  );
  const { subsections } = haccpForm[id];
  const titles = [];
  function getActiveTitles(obj) {
    Object.keys(obj).forEach(key => {
      if (template[obj[key].name] && names.includes(obj[key].name))
        titles.push(obj[key].title);
    });
  }
  getActiveTitles(subsections);
  return titles.join(', ');
}

const ccpList = [
  'arubeszerzes_aruatvetel',
  'beszallitas_kulsos_ceg_altal',
  'hutve',
  'fagyasztva',
  'felengedtetes',
  'hokezeles',
  'zsirban_olajban_sutes',
  'talal_melegen_tartas',
  'etelk_melegen_tartas',
];

function generateCcps(template) {
  let count = 1;
  ccpList.forEach(ccp => {
    if (template[ccp]) {
      template[`${ccp}_num`] = count;
      count += 1;
    }
  });
}

function generateEmptyClientForm(clientForm) {
  const emptyClientForm = {};
  Object.values(clientForm).forEach(el => {
    if (el.type === 'TextInput') emptyClientForm[el.name] = '';
  });
  return emptyClientForm;
}

export default function generateTemplate() {
  const state = store.getState();

  const emptyCompForm = generateEmptyClientForm(clientForm);
  const template = {
    ...emptyCompForm,
    ...state.clientData,
    ...state.haccpData,
  };
  template.kiadasdatum = formatDate(template.kiadasdatum);
  template.kovvizsg = formatDate(template.kovvizsg);
  template.tevkorok = generateListFor('tevkor', template);
  template.munkakorok = generateListFor('munkakor', template);
  template.adatok = generateAdatok(haccpForm, template);
  template.takar_helyisegek = generateTakarHely('helyisegek', template);
  template.beszer = generateBeszer(template);
  generateCcps(template);
  template.hutesTable = hutesTable(template);
  template.tarolasTable = tarolasTable(template);
  template.zsirTable = zsirTable(template);
  template.sutemenyTable = sutemenyTable(template);

  console.log(template);
  return template;
}
