import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import {
  CHANGE_CLIENT_DATA,
  CHANGE_HACCP_DATA,
} from '../../redux/actions/actionTypes';

const useStyles = makeStyles(() => ({
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  formControl: {
    width: 200,
  },
  select: {
    padding: '11px 14px',
    '&:focus': {
      background: '#fafafa',
    },
  },
}));

function SelectInput({ name, title, haccp }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector(
    state => state[haccp ? 'haccpData' : 'clientData'][name]
  );
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      margin="dense"
      className={classes.formControl}
    >
      <InputLabel
        classes={{
          root: classes.InputLabelRoot,
        }}
        ref={inputLabel}
        htmlFor={name}
      >
        {title}
      </InputLabel>
      <Select
        value={value || ''}
        onChange={e =>
          dispatch({
            type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
            payload: { [name]: e.target.value },
          })
        }
        classes={{
          select: classes.select,
        }}
        input={<OutlinedInput labelWidth={labelWidth} name={name} id={name} />}
      >
        <MenuItem value="">
          <em>Nincs</em>
        </MenuItem>
        <MenuItem value="gyemant">Gyémánt</MenuItem>
        <MenuItem value="platina">Platina</MenuItem>
        <MenuItem value="arany">Arany</MenuItem>
        <MenuItem value="ezust">Ezüst</MenuItem>
      </Select>
    </FormControl>
  );
}

export default SelectInput;
