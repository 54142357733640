import { compose } from 'redux';

function desc(a, b, orderBy) {
  const ac = (a[orderBy] ?? '').toString();
  const bc = (b[orderBy] ?? '').toString();
  return ac.localeCompare(bc, 'hu', { numeric: true, ignorePunctuation: true });
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const transform = data =>
  data.map(c => {
    const res = {};
    Object.entries(c).forEach(([k, v]) => {
      if (!v) res[k] = '';
      else if (k === 'nr') res[k] = `${v}.`;
      else if (k === 'kiadasdatum' || k === 'kovvizsg')
        res[k] = `${v.replace(/-/g, '. ')}.`;
      else if (k === 'ugyfelkat') {
        if (v === 'ezust') res[k] = 'Ezüst';
        else if (v === 'gyemant') res[k] = 'Gyémánt';
        else res[k] = v.charAt(0).toUpperCase() + v.slice(1);
      } else res[k] = v.toString();
    });
    return res;
  });

const filter = filterValue => data =>
  filterValue
    ? data.filter(c =>
        Object.values(c).some(v =>
          (v || '')
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase()),
        ),
      )
    : data;

export const transFilt = filterValue => compose(filter(filterValue), transform);

const sort = (order, orderBy) => data =>
  [...data].sort(getSorting(order, orderBy));

const paginate = (rowsPerPage, page) => data =>
  rowsPerPage > 0
    ? data.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    : data;

const display = data =>
  data.map(r => {
    const res = {};
    Object.entries(r).forEach(([k, v]) => {
      if (v.length > 24) {
        res[k] = { name: `${v.slice(0, 24)}…`, title: v };
      } else res[k] = v;
    });
    return res;
  });

export const processTableData = (
  filterValue,
  order,
  orderBy,
  rowsPerPage,
  page,
) =>
  compose(
    display,
    paginate(rowsPerPage, page),
    sort(order, orderBy),
    filter(filterValue),
    transform,
  );
