import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { changeEgyeb } from '../redux/actions';

const styles = theme => ({
  textFieldCont: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

class Egyeb extends React.Component {
  handleEgyeb = e => {
    const egyeb = e.target.value;
    const { countMain } = this.props;
    this.props.changeEgyeb({ egyeb, countMain });
  };

  render() {
    const { classes, egyeb } = this.props;
    return (
      <div className={classes.textFieldCont}>
        <TextField
          id="outlined-textarea-egyéb"
          label="Egyéb"
          placeholder="Egyéb"
          margin="normal"
          multiline
          variant="outlined"
          fullWidth
          defaultValue={egyeb}
          onBlur={this.handleEgyeb}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const egyeb = state.questions.cselTervs[ownProps.countMain].filter(
    x => x.egyeb,
  ).length
    ? state.questions.cselTervs[ownProps.countMain].filter(x => x.egyeb)[0].name
    : '';
  return { egyeb };
};

export default connect(mapStateToProps, {
  changeEgyeb,
})(withStyles(styles)(Egyeb));
