export const CHANGE_CLIENT_DATA = 'CHANGE_CLIENT_DATA';
export const CHANGE_HACCP_DATA = 'CHANGE_HACCP_DATA';

export const SAVE_CLIENT_REQUEST = 'SAVE_CLIENT_REQUEST';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAILURE = 'SAVE_CLIENT_FAILURE';

export const SAVE_HACCP_REQUEST = 'SAVE_HACCP_REQUEST';
export const SAVE_HACCP_SUCCESS = 'SAVE_HACCP_SUCCESS';
export const SAVE_HACCP_FAILURE = 'SAVE_HACCP_FAILURE';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

export const GET_CLIENT_DATA_REQUEST = 'GET_CLIENT_DATA_REQUEST';
export const GET_CLIENT_DATA_SUCCESS = 'GET_CLIENT_DATA_SUCCESS';
export const GET_CLIENT_DATA_FAILURE = 'GET_CLIENT_DATA_FAILURE';
export const RESET_CLIENT = 'RESET_CLIENT';

export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

export const GET_HACCPS_REQUEST = 'GET_HACCPS_REQUEST';
export const GET_HACCPS_SUCCESS = 'GET_HACCPS_SUCCESS';
export const GET_HACCPS_FAILURE = 'GET_HACCPS_FAILURE';
export const RESET_HACCP = 'RESET_HACCP';

export const GET_HACCP_DATA_REQUEST = 'GET_HACCP_DATA_REQUEST';
export const GET_HACCP_DATA_SUCCESS = 'GET_HACCP_DATA_SUCCESS';
export const GET_HACCP_DATA_FAILURE = 'GET_HACCP_DATA_FAILURE';

export const SET_SELECTED_HACCP = 'SET_SELECTED_HACCP';

export const GENERATE_DOC_REQUEST = 'GENERATE_DOC_REQUEST';
export const GENERATE_DOC_END = 'GENERATE_DOC_END';
