import {
  GET_CLIENTS_DETAILS_REQUEST,
  GET_CLIENTS_DETAILS_SUCCESS,
  GET_CLIENTS_DETAILS_FAILURE,
} from '../actions/types';

const initialState = {
  isFetching: false,
  error: null,
  response: null,
};

export default function(state = initialState, action) {
  const { type, response, error } = action;
  switch (type) {
    case GET_CLIENTS_DETAILS_REQUEST:
      return { ...state, isFetching: true, error: null, response: null };
    case GET_CLIENTS_DETAILS_SUCCESS:
      return {
        ...state,
        response: response.map((c, i) => ({ ...c, nr: i + 1 })),
        isFetching: false,
      };
    case GET_CLIENTS_DETAILS_FAILURE:
      return { ...state, error: error.message, isFetching: false };
    default:
      return state;
  }
}
