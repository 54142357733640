import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
  response: null,
};

export default function(state = initialState, action) {
  const { type, response, error } = action;
  switch (type) {
    case GET_CLIENTS_REQUEST:
      return { ...state, isFetching: true, error: null, response: null };
    case GET_CLIENTS_SUCCESS:
      return { ...state, response, isFetching: false };
    case GET_CLIENTS_FAILURE:
      return { ...state, error: error.message, isFetching: false };
    default:
      return state;
  }
}
