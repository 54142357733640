import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  changeCegnev,
  changeTelephely,
  changeCim,
  changeKeszitette,
  changeUgyfelKat,
  changeDate,
} from '../redux/actions';

const styles = theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    fontWeight: 'bold',
    fontSize: '1.875rem',
  },
  InputRoot: {
    padding: '11px 14px',
  },
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  input: {
    display: 'none',
  },
  formControl: {
    width: 200,
  },
  select: {
    padding: '11px 14px',
  },
  date: {
    width: 200,
  },
});

class PreQuestions extends Component {
  state = {
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleCegnev = e => {
    const cegnev = e.target.value;
    this.props.changeCegnev({ cegnev });
  };

  handleTelephely = e => {
    const telephely = e.target.value;
    this.props.changeTelephely({ telephely });
  };

  handleCim = e => {
    const cim = e.target.value;
    this.props.changeCim({ cim });
  };

  handleKeszitette = e => {
    const keszitette = e.target.value;
    this.props.changeKeszitette({ keszitette });
  };

  handleUgyfelKat = e => {
    const ugyfelKat = e.target.value;
    this.props.changeUgyfelKat({ ugyfelKat });
  };

  handleDate = e => {
    const date = e.target.value;
    this.props.changeDate({ date });
  };

  render() {
    const {
      classes,
      cegnev,
      telephely,
      cim,
      keszitette,
      ugyfelKat,
      date,
    } = this.props;
    const { labelWidth } = this.state;
    return (
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom className={classes.main}>
          Adatok
        </Typography>
        <TextField
          id="outlined-textarea-cegnev"
          label="Cégnév"
          placeholder="Cégnév"
          margin="normal"
          multiline
          variant="outlined"
          fullWidth
          defaultValue={cegnev}
          InputProps={{
            classes: {
              root: classes.InputRoot,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.InputLabelRoot,
            },
          }}
          onBlur={this.handleCegnev}
        />
        <TextField
          id="outlined-textarea-telephely"
          label="Telephely neve"
          placeholder="Telephely neve"
          margin="normal"
          multiline
          variant="outlined"
          fullWidth
          defaultValue={telephely}
          InputProps={{
            classes: {
              root: classes.InputRoot,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.InputLabelRoot,
            },
          }}
          onBlur={this.handleTelephely}
        />
        <TextField
          id="outlined-textarea-cim"
          label="Cím"
          placeholder="Cím"
          margin="normal"
          multiline
          variant="outlined"
          fullWidth
          defaultValue={cim}
          InputProps={{
            classes: {
              root: classes.InputRoot,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.InputLabelRoot,
            },
          }}
          onBlur={this.handleCim}
        />
        <TextField
          id="outlined-textarea-keszitette"
          label="Készítette"
          placeholder="Készítette"
          margin="normal"
          multiline
          variant="outlined"
          fullWidth
          defaultValue={keszitette}
          InputProps={{
            classes: {
              root: classes.InputRoot,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.InputLabelRoot,
            },
          }}
          onBlur={this.handleKeszitette}
        />
        <FormControl
          variant="outlined"
          margin="normal"
          className={classes.formControl}
        >
          <InputLabel
            classes={{
              root: classes.InputLabelRoot,
            }}
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="ugyfel-kat"
          >
            Ügyfél-kategória
          </InputLabel>
          <Select
            value={ugyfelKat}
            onChange={this.handleUgyfelKat}
            classes={{
              select: classes.select,
            }}
            input={
              <OutlinedInput
                labelWidth={labelWidth}
                name="kat"
                id="ugyfel-kat"
              />
            }
          >
            <MenuItem value="">
              <em>Nincs</em>
            </MenuItem>
            <MenuItem value="gyemant">Gyémánt</MenuItem>
            <MenuItem value="platina">Platina</MenuItem>
            <MenuItem value="arany">Arany</MenuItem>
            <MenuItem value="ezust">Ezüst</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="date"
          label="Dátum"
          type="date"
          variant="outlined"
          margin="normal"
          defaultValue={date}
          className={classes.date}
          inputProps={{
            className: classes.InputRoot,
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.InputLabelRoot,
            },
          }}
          onBlur={this.handleDate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cegnev } = state.data;
  const { telephely } = state.data;
  const { cim } = state.data;
  const { keszitette } = state.data;
  const { ugyfelKat } = state.data;
  const { date } = state.data;
  return { cegnev, telephely, cim, keszitette, ugyfelKat, date };
};

export default connect(mapStateToProps, {
  changeCegnev,
  changeTelephely,
  changeCim,
  changeKeszitette,
  changeUgyfelKat,
  changeDate,
})(withStyles(styles)(PreQuestions));
