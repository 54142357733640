import {
  SAVE_HACCP_REQUEST,
  SAVE_HACCP_SUCCESS,
  SAVE_HACCP_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  isFetching: false,
  error: null,
};

export default function(state = initialState, action) {
  const { type, error } = action;
  switch (type) {
    case SAVE_HACCP_REQUEST:
      return { ...state, isFetching: true, error: null, response: null };
    case SAVE_HACCP_SUCCESS:
      return { ...state, isFetching: false };
    case SAVE_HACCP_FAILURE:
      return { ...state, error: error.message, isFetching: false };
    default:
      return state;
  }
}
