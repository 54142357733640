import { GENERATE_DOC_REQUEST, GENERATE_DOC_END } from '../actions/actionTypes';

export default function(state = false, action) {
  switch (action.type) {
    case GENERATE_DOC_REQUEST:
      return true;
    case GENERATE_DOC_END:
      return false;
    default:
      return state;
  }
}
