import { combineReducers } from 'redux';
import auth from './auth';
import clientsDetails from './clientsDetails';
import selectedMenu from './selectedMenu';
import clientData from '../../wizard/redux/reducers/clientData';
import haccpData from '../../wizard/redux/reducers/haccpData';
import saveClient from '../../wizard/redux/reducers/saveClient';
import saveHaccp from '../../wizard/redux/reducers/saveHaccp';
import getClients from '../../wizard/redux/reducers/getClients';
import selectedClient from '../../wizard/redux/reducers/selectedClient';
import getHaccps from '../../wizard/redux/reducers/getHaccps';
import selectedHaccp from '../../wizard/redux/reducers/selectedHaccp';
import generateDoc from '../../wizard/redux/reducers/generateDoc';
import changeActive from '../../audit/redux/reducers/changeActive';
import changeComment from '../../audit/redux/reducers/changeComment';
import images from '../../audit/redux/reducers/images';
import questions from '../../audit/redux/reducers/questions';
import data from '../../audit/redux/reducers/data';
import mainHide from '../../audit/redux/reducers/mainHide';

export default combineReducers({
  auth,
  clientsDetails,
  selectedMenu,
  clientData,
  haccpData,
  saveClient,
  saveHaccp,
  getClients,
  selectedClient,
  getHaccps,
  selectedHaccp,
  generateDoc,
  changeActive,
  changeComment,
  images,
  questions,
  data,
  mainHide,
});
