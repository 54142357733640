import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import saveClient from '../redux/actions/saveClient';
import saveHaccp from '../redux/actions/saveHaccp';
import generateDocx from '../docxtemplater/generateDocx';

const useStyles = makeStyles(theme => ({
  fixed: {
    position: 'fixed',
    display: 'flex',
    bottom: theme.spacing(2),
    right: theme.spacing(4),
    [theme.breakpoints.down(400)]: {
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  wrapper: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(400)]: {
      fontSize: 10,
    },
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function FixedBtnPanel() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFetchingClientSave = useSelector(
    state => state.saveClient.isFetching
  );
  const isFetchingHaccpSave = useSelector(state => state.saveHaccp.isFetching);
  const isGeneratingDoc = useSelector(state => state.generateDoc);

  const saveClientClick = () => {
    dispatch(saveClient());
  };

  const saveHaccpClick = () => {
    dispatch(saveHaccp());
  };

  return (
    <div className={classes.fixed}>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isFetchingClientSave}
          startIcon={<SaveIcon />}
          className={classes.button}
          onClick={saveClientClick}
        >
          Ügyfél
        </Button>
        {isFetchingClientSave && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isFetchingHaccpSave}
          startIcon={<SaveIcon />}
          className={classes.button}
          onClick={saveHaccpClick}
        >
          Haccp
        </Button>
        {isFetchingHaccpSave && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={isGeneratingDoc}
          startIcon={<DescriptionIcon />}
          className={classes.button}
          onClick={generateDocx}
        >
          Letölt
        </Button>
        {isGeneratingDoc && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}
