import { SET_SELECTED_MENU } from '../actions/types';

const initialState = {
  selected: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_MENU: {
      return { selected: action.index };
    }
    default:
      return state;
  }
}
