import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';
import { loginUser } from '../../redux/actions';
import FadeIn from '../misc/FadeIn';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: '#eee',
    },
  },
  paper: {
    marginTop: 100,
    display: 'flex',
    padding: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: 50,
    height: 50,
  },
  form: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.main,
    marginBottom: 5,
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const loginError = useSelector(state => state.auth.loginError);
  const classes = useStyles();

  const handleSubmit = e => {
    e.preventDefault();
    if (username && password) dispatch(loginUser(username, password));
  };

  if (user) return <Redirect to="/clients" />;

  return (
    <FadeIn>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Bejelentkezés
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email cím"
              name="email"
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Jelszó"
              type="password"
              id="password"
              onChange={e => setPassword(e.target.value)}
            />
            {loginError && (
              <Typography component="p" className={classes.errorText}>
                Hibás email vagy jelszó.
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
            >
              Bejelentkezés
            </Button>
          </form>
        </Paper>
      </Container>
    </FadeIn>
  );
}
