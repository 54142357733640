export default function sutemenyTable(template) {
  let xml = `<w:tbl>
  <w:tblPr>
    <w:tblW w:w="5000" w:type="pct" />
    <w:jc w:val="center"/>
    <w:tblBorders><w:top w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:left w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:right w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto"/><w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto"/></w:tblBorders>
  </w:tblPr>
  <w:tblGrid>
    <w:gridCol/>
    <w:gridCol/>
    <w:gridCol/>
  </w:tblGrid>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
          </w:rPr>
          <w:t>Sütemény</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
          </w:rPr>
          <w:t>Tárolási hőmérséklet</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:rPr>
            <w:b/>
          </w:rPr>
          <w:t>Fogyaszthatósági határidő</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (
    template.helybenk_muffin ||
    template.helybenk_pogacsa ||
    template.helybenk_linzerek
  )
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Szárazsütemények, pogácsák töltelék nélkül</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
        </w:pPr>
        <w:r>
          <w:t>Száraz, szellős, hűvös helyen tartva</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>7–10 nap</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Szárazsütemények, leveles tészták lekvárral, kakaóval, dióval stb. töltve</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Száraz, szellős, hűvös helyen tartva</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>3–7 nap</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  if (template.helybenk_tortak || template.helybenk_sutemenyek)
    xml += `<w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Szárazsütemények, leveles tészták állati eredetű élelmiszerrel (túró, hús, sajtkrém stb.) töltve</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>0 – +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>48 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Vajkrémes sütemények</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>0 – +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>72 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Főzött krémmel töltött sütemények</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>0 – +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>48 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
      <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Tejszínes, sárga krémmel töltött sütemények</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>0 – +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>48 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>
  <w:tr>
    <w:trPr>
      <w:trHeight w:val="281"/>
      <w:jc w:val="center"/>
    </w:trPr>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>Fagyasztott, majd felolvasztott sütemények</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>0 – +5 °C</w:t>
        </w:r>
      </w:p>
    </w:tc>
    <w:tc>
      <w:p>
        <w:pPr>
          <w:jc w:val="center"/>
          <w:spacing w:before="71" w:line="360" w:lineRule="auto"/>
        </w:pPr>
        <w:r>
          <w:t>48 óra</w:t>
        </w:r>
      </w:p>
    </w:tc>
  </w:tr>`;

  xml += `</w:tbl>`;
  return xml;
}
