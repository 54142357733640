import {
  CHANGE_CLIENT_DATA,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  GET_CLIENT_DATA_FAILURE,
  RESET_CLIENT,
} from '../actions/actionTypes';
import baseUrl from '../../../resources/api';

function getISODate(ISOString) {
  return ISOString.slice(0, 10);
}

const currDate = new Date();

function oneYearFrom(date) {
  const oneYearLater = new Date(date.valueOf());
  oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
  return oneYearLater;
}

const initialState = {
  kiadasdatum: getISODate(currDate.toISOString()),
  kovvizsg: getISODate(oneYearFrom(currDate).toISOString()),
  isFetching: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_CLIENT_DATA: {
      const newProps = { ...action.payload };
      if (newProps.kiadasdatum) {
        newProps.kovvizsg = getISODate(
          oneYearFrom(new Date(newProps.kiadasdatum)).toISOString(),
        );
      }
      return {
        ...state,
        ...newProps,
      };
    }
    case GET_CLIENT_DATA_REQUEST:
      return { ...state, isFetching: true, error: null };
    case GET_CLIENT_DATA_SUCCESS: {
      const alaprajzImg = action.data.alaprajz
        ? {
            alaprajz: {
              src: `${baseUrl}/alaprajz_imgs/${action.data.alaprajz.src}`,
            },
          }
        : {};
      return { ...action.data, ...alaprajzImg };
    }
    case GET_CLIENT_DATA_FAILURE:
      return { ...state, error: action.error.message, isFetching: false };
    case RESET_CLIENT:
      return initialState;
    default:
      return state;
  }
}
