import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import TextInput from './formfields/TextInput';
import SelectInput from './formfields/SelectInput';
import DateInput from './formfields/DateInput';
import CheckboxBlock from './formfields/CheckboxBlock';
import ImageInput from './formfields/ImageInput';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    marginTop: '30px',
  },
  sub: {
    marginTop: '15px',
    marginLeft: '30px',
  },
  icon: {
    transition: 'transform 150ms linear',
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
}));

const components = {
  TextInput,
  SelectInput,
  DateInput,
  CheckboxBlock,
  ImageInput,
};

export default function Section({ section, parent = [] }) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const Component = components[section.type];

  return (
    <div className={classes[section.header]}>
      {section.header && (
        <div className={classes.titleRow}>
          <Typography
            variant={section.header === 'main' ? 'h5' : 'h6'}
            className={classes.title}
          >
            {section.title}
          </Typography>
          <IconButton aria-label="hide" onClick={() => setOpen(!isOpen)}>
            <ExpandMoreIcon
              fontSize="large"
              classes={{
                root: `${classes.icon}${!isOpen ? ` ${classes.rotate}` : ''}`,
              }}
            />
          </IconButton>
        </div>
      )}
      {section.type !== 'main' && isOpen && (
        <Component {...section} parent={parent} haccp />
      )}
      {section.type === 'main' &&
        isOpen &&
        Object.keys(section.subsections).map(key => (
          <Section
            key={key}
            section={section.subsections[key]}
            parent={[...parent, section.name]}
          />
        ))}
    </div>
  );
}
