import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDIESsNHwy7LeMJkV2dase8xwDKcbaMoNI',
  authDomain: 'sattva-916db.firebaseapp.com',
  databaseURL: 'https://sattva-916db.firebaseio.com',
  projectId: 'sattva-916db',
  storageBucket: 'sattva-916db.appspot.com',
  messagingSenderId: '319458568482',
  appId: '1:319458568482:web:bb5f8d78ec37cd90bb1d7b',
});

export default firebaseApp;
