import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { connect } from 'react-redux';
import Egyeb from './Egyeb';
import { changeCselSelected } from '../redux/actions';

class ResponsiveDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleToggle = (i, cselCountMain) => {
    const { countMain } = this.props;
    this.props.changeCselSelected({ countMain, i, cselCountMain });
  };

  render() {
    const { fullScreen, cselTerv, isSelected, countMain } = this.props;
    return (
      <div>
        <Button
          variant="contained"
          color="default"
          fullWidth
          onClick={this.handleClickOpen}
        >
          Cselekvési terv
        </Button>
        <Dialog
          fullScreen={fullScreen}
          maxWidth="lg"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Cselekvési terv
          </DialogTitle>
          <DialogContent>
            <List className="">
              {cselTerv
                .filter(x => !x.egyeb)
                .map((x, i) =>
                  x.isMain ? (
                    <ListItem key={i}>
                      <ListItemText
                        primary={x.name}
                        primaryTypographyProps={{ variant: 'h6' }}
                      />
                    </ListItem>
                  ) : (
                    <ListItem
                      key={i}
                      button
                      onClick={this.handleToggle.bind(this, i, x.cselCountMain)}
                    >
                      <Checkbox
                        checked={isSelected[i].isSelected || false}
                        tabIndex={-1}
                        color="primary"
                        disableRipple
                      />
                      <ListItemText primary={x.name} />
                    </ListItem>
                  ),
                )}
            </List>
            <Egyeb countMain={countMain} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Kész
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const isSelected = state.questions.cselTervs[ownProps.countMain];
  return { isSelected };
};

export default connect(mapStateToProps, { changeCselSelected })(
  withMobileDialog()(ResponsiveDialog),
);
