import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import form from '../resources/haccpForm.json';
import Section from './Section';
import SelectHaccp from './SelectHaccp';

const useStyles = makeStyles(() => ({
  haccpForm: {
    marginTop: 50,
    marginBottom: 90,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    marginBottom: 20,
  },
}));

export default function HaccpForm() {
  const classes = useStyles();

  return (
    <div className={classes.haccpForm}>
      <Typography variant="h5" className={classes.title}>
        Haccp adatok
      </Typography>
      <SelectHaccp />
      {Object.keys(form).map(key => (
        <Section key={key} section={form[key]} />
      ))}
    </div>
  );
}
