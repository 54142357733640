import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  cont: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
});

function LoadingCircle({ classes }) {
  return (
    <div className={classes.cont}>
      <CircularProgress size={28} />
    </div>
  );
}

export default withStyles(styles)(LoadingCircle);
