import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Form from './components/Form';
import generatePdf from './misc/generatePdf';
import logo from './assets/logo';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const styles = theme => ({
  container: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1100 + theme.spacing(6))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  pagination: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  pagButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomPagButtons: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  step: {
    paddingRight: 0,
  },
  touchRipple: {
    color: 'rgba(0, 0, 0, 0.15)',
    left: '-4px',
    borderRadius: '4px',
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    width: '40px',
    marginRight: '10px',
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      downloadInProgress: false,
    };
  }

  handlePageInc = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  handlePageIncBottom = () => {
    window.scrollTo(0, 0);
    this.handlePageInc();
  };

  handlePageDec = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };

  handlePageDecBottom = () => {
    window.scrollTo(0, 0);
    this.handlePageDec();
  };

  handleStep = step => {
    this.setState({ page: step });
  };

  handleDownload = () => {
    this.setState({ downloadInProgress: true });
    const {
      questions,
      active,
      cselTervs,
      comments,
      imgsrcs,
      data,
    } = this.props;
    if (Object.keys(active).length) {
      const docDefinition = generatePdf(
        questions,
        active,
        cselTervs,
        comments,
        imgsrcs,
        logo,
        data,
      );
      console.log(docDefinition);
      pdfMake
        .createPdf(docDefinition)
        .download(
          `${data.telephely || 'Report'}_${new Date()
            .toISOString()
            .slice(0, 10)}.pdf`,
          () => {
            this.setState({ downloadInProgress: false });
          },
        );
    }
  };

  render() {
    const { classes } = this.props;
    const steps = ['', '', '', '', ''];
    return (
      <div className={classes.container}>
        <div className={classes.pagination}>
          <Stepper
            nonLinear
            activeStep={this.state.page - 1}
            classes={{
              root: classes.stepper,
            }}
          >
            {steps.map((label, index) => {
              return (
                <Step
                  key={index}
                  classes={{
                    root: classes.step,
                  }}
                >
                  <StepButton
                    onClick={this.handleStep.bind(this, index + 1)}
                    completed={false}
                    classes={{
                      touchRipple: classes.touchRipple,
                    }}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.pagButtons}>
            {this.state.page === 1 ? (
              <div />
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handlePageDec}
              >
                Előző
              </Button>
            )}
            {this.state.page === 5 ? (
              <div />
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handlePageInc}
              >
                Következő
              </Button>
            )}
          </div>
        </div>
        <Form page={this.state.page} />
        <div className={classes.bottomPagButtons}>
          {this.state.page === 1 ? (
            <div />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handlePageDecBottom}
            >
              Előző
            </Button>
          )}
          {this.state.page === 5 ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleDownload}
            >
              Letöltés
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handlePageIncBottom}
            >
              Következő
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { active } = state.changeActive;
  const imgsrcs = state.images.imgsArrs;
  const { comments } = state.changeComment;
  const { questions } = state.questions;
  const { cselTervs } = state.questions;
  const { data } = state;
  return { active, imgsrcs, comments, questions, cselTervs, data };
};

export default connect(mapStateToProps, null)(withStyles(styles)(App));
