import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import {
  CHANGE_CLIENT_DATA,
  CHANGE_HACCP_DATA,
} from '../../redux/actions/actionTypes';

const useStyles = makeStyles(() => ({
  Input: {
    padding: '10px 12px',
  },
  InputLabelRoot: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  input: {
    display: 'none',
  },
}));

function TextInput({ name, title, adornment, haccp, parent }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const value = useSelector(
    state => state[haccp ? 'haccpData' : 'clientData'][name],
  );
  return (
    <TextField
      id={`outlined-textarea-${name}`}
      label={title}
      margin="dense"
      variant="outlined"
      fullWidth
      value={value || ''}
      InputProps={{
        endAdornment: adornment && (
          <InputAdornment position="end">{adornment}</InputAdornment>
        ),
        classes: {
          input: classes.Input,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.InputLabelRoot,
        },
      }}
      onChange={e =>
        dispatch({
          type: haccp ? CHANGE_HACCP_DATA : CHANGE_CLIENT_DATA,
          payload: { [name]: e.target.value },
          parent,
        })
      }
    />
  );
}

export default TextInput;
