import {
  CHANGE_HACCP_DATA,
  GET_HACCP_DATA_REQUEST,
  GET_HACCP_DATA_SUCCESS,
  GET_HACCP_DATA_FAILURE,
  RESET_HACCP,
} from '../actions/actionTypes';
import haccpForm from '../../resources/haccpForm.json';

function findItem(name, obj) {
  for (const v of Object.values(obj)) {
    if (v.name === name) return v.options || v.subsections;
    if (v.options && findItem(name, v.options))
      return findItem(name, v.options);
    if (v.subsections && findItem(name, v.subsections))
      return findItem(name, v.subsections);
  }
  return null;
}

function hasActiveChild(obj, state) {
  if (obj) {
    for (const v of Object.values(obj)) {
      if (state[v.name]) return true;
      if (v.options && hasActiveChild(v.options, state)) return true;
      if (v.subsections && hasActiveChild(v.subsections, state)) return true;
    }
  }
  return false;
}

export default function(state = {}, action) {
  switch (action.type) {
    case CHANGE_HACCP_DATA: {
      const newState = {
        ...state,
        ...action.payload,
      };
      const key = Object.keys(action.payload)[0];
      if (action.payload[key]) {
        action.parent.forEach(par => {
          newState[par] = true;
        });
      } else {
        const reversed = [...action.parent].reverse();
        reversed.forEach(par => {
          const item = findItem(par, haccpForm);
          if (!hasActiveChild(item, newState)) newState[par] = false;
        });
      }
      return newState;
    }
    case GET_HACCP_DATA_REQUEST:
      return { ...state, isFetching: true, error: null };
    case GET_HACCP_DATA_SUCCESS:
      if (action.data) return { ...action.data };
      return state;
    case GET_HACCP_DATA_FAILURE:
      return { ...state, error: action.error.message, isFetching: false };
    case RESET_HACCP:
      return {};
    default:
      return state;
  }
}
