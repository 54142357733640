import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function ResponsiveDialog({
  buttonElem,
  title,
  content,
  action,
  actionFn,
  openFn,
  closeFn,
}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    if (openFn) openFn();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (closeFn) closeFn();
  };

  return (
    <>
      {React.cloneElement(buttonElem, { onClick: handleClickOpen })}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={actionFn} color="primary">
            {action}
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Bezár
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
