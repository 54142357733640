import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import pages from '../constants/pages';
import { logoutUser } from '../redux/actions';
import setSelectedMenu from '../redux/actions/selectedMenu';
import FadeIn from './misc/FadeIn';
import logo from '../img/SATTVA.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  brand: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  headerLogo: {
    width: 38,
    marginRight: theme.spacing(1.5),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
    },
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const user = useSelector(state => state.auth.user);
  const selectedIndex = useSelector(state => state.selectedMenu.selected);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const i = pages.findIndex(
      p => p.path === pathname || p.path === `${pathname}/`,
    );
    if (i >= 0) dispatch(setSelectedMenu(i));
  }, [dispatch, pathname]);

  if (!user) return <div>{children}</div>;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <FadeIn>
        <Divider />
        <List>
          {pages.map(({ name, path, Icon }, i) => (
            <ListItem
              key={name}
              button
              component={Link}
              to={path}
              selected={selectedIndex === i}
              onClick={() => {
                setMobileOpen(false);
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Kilépés" />
          </ListItem>
        </List>
      </FadeIn>
    </div>
  );

  return (
    <>
      <AppBar position="sticky" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.brand}>
            <img src={logo} alt="logo" className={classes.headerLogo} />
            <Typography variant="h6" noWrap>
              Sattva admin
            </Typography>
          </div>
          <Button
            color="inherit"
            startIcon={<ExitToAppIcon />}
            onClick={logout}
          >
            Kilépés
          </Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.main}>{children}</main>
    </>
  );
}
