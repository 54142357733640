import {
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
} from './types';
import store from '../store';
import setClient from '../../wizard/redux/actions/setClient';
import getClientsDetails from './getClientsDetails';
import baseUrl from '../../resources/api';

export default function(id) {
  return dispatch => {
    dispatch({ type: DELETE_CLIENT_REQUEST });
    const state = store.getState();
    if (parseInt(id, 10) === state.selectedClient)
      dispatch(setClient('new_client'));
    return fetch(`${baseUrl}/client/${id}`, {
      method: 'delete',
      mode: 'cors',
    })
      .then(response => {
        console.log(response);
        if (!response.ok) throw new Error(response.status);
        return response.json();
      })
      .then(response => {
        console.log(response);
        dispatch({ type: DELETE_CLIENT_SUCCESS });
        dispatch(getClientsDetails());
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: DELETE_CLIENT_FAILURE, error });
      });
  };
}
