import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ViewListIcon from '@material-ui/icons/ViewList';
import DialogContentText from '@material-ui/core/DialogContentText';
import { batchActions } from 'redux-batched-actions';
import setClient from '../../wizard/redux/actions/setClient';
import Dialog from './Dialog';
import ClientDataForm from '../../wizard/components/ClientDataForm';
import saveClient from '../../wizard/redux/actions/saveClient';
import deleteClient from '../../redux/actions/deleteClient';
import {
  changeCim,
  changeCegnev,
  changeTelephely,
  changeUgyfelKat,
} from '../../audit/redux/actions';
import getClientsDetails from '../../redux/actions/getClientsDetails';

export default function ActionButtons({ id }) {
  const dispatch = useDispatch();
  const client = useSelector(state => {
    const clients = state.clientsDetails.response;
    return clients.find(c => c.id === parseInt(id, 10));
  });
  const history = useHistory();

  const selectClient = id => {
    dispatch(setClient(id));
  };

  const handleWizardClick = id => {
    dispatch(setClient(id));
    history.push('/wizard');
  };

  const handleAuditClick = () => {
    dispatch(
      batchActions(
        [
          changeCegnev({ cegnev: client.cegnev || '' }),
          changeTelephely({ telephely: client.telepnev || '' }),
          changeUgyfelKat({ ugyfelKat: client.ugyfelkat || '' }),
          changeCim({ cim: client.telepcim || '' }),
        ],
        'UPDATE_AUDIT_CLIENT_BATCH',
      ),
    );
    history.push('/audit');
  };

  return (
    <>
      <Dialog
        buttonElem={
          <IconButton
            color="primary"
            size="small"
            aria-label="edit"
            component="span"
            title="Szerkeszt"
          >
            <EditIcon />
          </IconButton>
        }
        content={<ClientDataForm />}
        action="Ment"
        actionFn={() => dispatch(saveClient())}
        openFn={() => selectClient(id)}
        closeFn={() => dispatch(getClientsDetails())}
      />
      <IconButton
        color="primary"
        size="small"
        aria-label="delete"
        component="span"
        title="Wizard"
        onClick={() => handleWizardClick(id)}
      >
        <NoteAddIcon />
      </IconButton>
      <IconButton
        color="primary"
        size="small"
        aria-label="delete"
        component="span"
        title="Audit"
        onClick={() => handleAuditClick(id)}
      >
        <ViewListIcon />
      </IconButton>
      <Dialog
        buttonElem={
          <IconButton
            color="primary"
            size="small"
            aria-label="delete"
            component="span"
            title="Töröl"
          >
            <DeleteIcon />
          </IconButton>
        }
        title={client.telepnev}
        content={<DialogContentText>Biztos, hogy törlöd?</DialogContentText>}
        action="Töröl"
        actionFn={() => dispatch(deleteClient(id))}
      />
    </>
  );
}
