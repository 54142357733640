export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const GET_CLIENTS_DETAILS_REQUEST = 'GET_CLIENTS_DETAILS_REQUEST';
export const GET_CLIENTS_DETAILS_SUCCESS = 'GET_CLIENTS_DETAILS_SUCCESS';
export const GET_CLIENTS_DETAILS_FAILURE = 'GET_CLIENTS_DETAILS_FAILURE';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';

export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';
